import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { calculateTotalBrokers } from '../utils/calculateTradeData';

// @ts-ignore
const Brokers = ({ trades, companies }) => {
    const totalBrokers = calculateTotalBrokers(trades, companies);

    return (
        <Stack className="dash-cards-body" spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                <Stack spacing={1}>
                    <Typography color="text.secondary" variant="overline">
                        Total Brokers (7d)
                    </Typography>
                    <Typography variant="h4">{totalBrokers}</Typography>
                </Stack>
                <Avatar sx={{background: 'orange'}}>
                    <PeopleAltIcon fontSize="large" />
                </Avatar>
            </Stack>
        </Stack>
    );
};

export default Brokers;
