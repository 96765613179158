import authHeader from './auth.header';
import axios from "axios";
import {handleRequestError} from "../errorHandler";

const SDM_SERVICE_URL = '/api/sdm';
const ctype = {'Content-Type': 'application/json'}

class DashboardService {
    async getMonthlyTrades() {
        try {
            const response = await axios.get(`${SDM_SERVICE_URL}/dashboard/monthlyTrades`,
                {
                    headers: {...authHeader(), ...ctype},
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async getTrades() {
        try {
            const response = await axios.get(`${SDM_SERVICE_URL}/dashboard/trades`,
                {
                    headers: {...authHeader(), ...ctype},
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new DashboardService();
