import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts';
import {Stack} from '@mui/material';
import {MoonLoader} from 'react-spinners';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Trade {
    executingBroker: string;
    executionTime: string;
    reportedTime: string;
}

interface BrokerChartProps {
    trades: Trade[];
}

export default function BrokerPieChart({trades}: BrokerChartProps) {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState({
        series: [],
        options: {}
    });

    const processTradesForChart = () => {
        setLoading(true);

        const brokerTradeCounts: Record<string, number> = {};

        trades.forEach(trade => {
            brokerTradeCounts[trade.executingBroker] = (brokerTradeCounts[trade.executingBroker] || 0) + 1;
        });

        const series = Object.values(brokerTradeCounts);
        const labels = Object.keys(brokerTradeCounts);

        setChartData({
            // @ts-ignore
            series,
            options: {
                chart: {
                    type: 'pie'
                },
                labels,
                legend: {
                    position: 'bottom'
                }
            }
        });

        setLoading(false);
    };

    useEffect(() => {
        if (trades.length > 0) {
            processTradesForChart();
        }
    }, [trades]);

    if (loading) {
        return (
            <Stack direction="column" alignItems="center">
                <MoonLoader loading={loading} size={50}/>
            </Stack>
        );
    }

    return (
        <Stack direction="column">
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="pie"
                height={350}
            />
        </Stack>
    );
}