import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, ListSubheader, Divider } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CategoryIcon from '@mui/icons-material/Category';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SpeedIcon from '@mui/icons-material/Speed';

interface MenuItemProps {
    drawerOpen: boolean;
}

const usePrivileges = () => {
    const [privileges, setPrivileges] = useState<string[]>([]);

    useEffect(() => {
        const storedPrivileges = JSON.parse(localStorage.getItem('privileges') || '[]');
        setPrivileges(storedPrivileges);
    }, []);

    return privileges;
};

export const generalItems = ({ drawerOpen }: MenuItemProps) => {
    const privileges = usePrivileges();

    return (
        <div>
            {privileges.includes('VIEW_DASHBOARD') && (
                <>
                    {drawerOpen && <ListSubheader>Dashboard</ListSubheader>}
                    <ListItem button component={Link} to="/MetricsDashboard">
                        <ListItemIcon>
                            <SpeedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Metrics" />
                    </ListItem>
                </>
            )}

            {drawerOpen && <ListSubheader>Transaction Data</ListSubheader>}
            <ListItem button component={Link} to="/TradeDashboard">
                <ListItemIcon>
                    <ShowChartIcon />
                </ListItemIcon>
                <ListItemText primary="Trades" />
            </ListItem>
        </div>
    );
};

export const AdminItems = ({ drawerOpen }: MenuItemProps) => {
    const privileges = usePrivileges();

    return (
        <div>
            {drawerOpen && <ListSubheader>Company Data</ListSubheader>}
            {privileges.includes('VIEW_COMPANY') && (
                <ListItem button component={Link} to="/CompanyDashboard">
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Companies" />
                </ListItem>
            )}
            {privileges.includes('VIEW_ACCOUNT') && (
                <ListItem button component={Link} to="/AccountDashboard">
                    <ListItemIcon>
                        <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="Accounts" />
                </ListItem>
            )}
            {privileges.includes('VIEW_ASSOCIATION') && (
                <ListItem button component={Link} to="/AssociationDashboard">
                    <ListItemIcon>
                        <DatasetLinkedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Associations" />
                </ListItem>
            )}
            <Divider />
            {privileges.includes('VIEW_USER') && (
                <>
                    {drawerOpen && <ListSubheader>User Data</ListSubheader>}
                    <ListItem button component={Link} to="/UserDashboard">
                        <ListItemIcon>
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                    <Divider />
                </>
            )}
            {drawerOpen && <ListSubheader>Contract Data</ListSubheader>}
            {privileges.includes('VIEW_EXCHANGE') && (
                <ListItem button component={Link} to="/ExchangeDashboard">
                    <ListItemIcon>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Exchanges" />
                </ListItem>
            )}
            {privileges.includes('VIEW_PRODUCT') && (
                <ListItem button component={Link} to="/ProductDashboard">
                    <ListItemIcon>
                        <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                </ListItem>
            )}
            <Divider />
            {privileges.includes('VIEW_ALIAS') && (
                <>
                    {drawerOpen && <ListSubheader>Mapping</ListSubheader>}
                    <ListItem button component={Link} to="/AliasDashboard">
                        <ListItemIcon>
                            <NorthEastIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aliases" />
                    </ListItem>
                    <Divider />
                </>
            )}
        </div>
    );
};