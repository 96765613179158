import React from 'react';
import {Avatar, LinearProgress, Stack, Typography} from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { calculateCompliantTradePercentage } from '../utils/calculateTradeData';

// @ts-ignore
const Compliance = ({ trades }) => {
    const totalCompliantTrades = calculateCompliantTradePercentage(trades);

    return (
        <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                <Stack spacing={1}>
                    <Typography color="text.secondary" variant="overline">
                        Total Compliant (7d)
                    </Typography>
                    <Typography variant="h4">{totalCompliantTrades}%</Typography>
                </Stack>
                <Avatar sx={{background: 'green'}}>
                    <VerifiedUserIcon fontSize="large"/>
                </Avatar>
            </Stack>
            <div>
                <LinearProgress value={totalCompliantTrades} variant="determinate"/>
            </div>
        </Stack>
    );
};

export default Compliance;
