import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { calculateTotalTrades } from '../utils/calculateTradeData';

// @ts-ignore
const Trades = ({ trades }) => {
    const totalTrades = calculateTotalTrades(trades);

    return (
        <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                <Stack spacing={1}>
                    <Typography color="text.secondary" variant="overline">
                        Total Trades
                    </Typography>
                    <Typography variant="h4">{totalTrades}</Typography>
                </Stack>
                <Avatar sx={{ background: 'red'}}>
                    <TrendingUpIcon fontSize="large" />
                </Avatar>
            </Stack>
        </Stack>
    );
};

export default Trades;
