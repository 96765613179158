import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Stack, Typography } from '@mui/material';
import { MoonLoader } from 'react-spinners';
import dayjs from "dayjs";

interface TradesChartProps {
    trades: any[];
}

export default function TradesChart({ trades }: TradesChartProps) {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState({
        series: [],
        options: {}
    });

    const processTradesForChart = () => {
        const today = new Date();
        const dayLabels: string[] = [];
        const dailyComplianceCounts: any[] = [];
        const brokerTradeCounts = {};

        for (let i = 29; i >= 0; i--) {
            const day = new Date(today);
            day.setDate(today.getDate() - i);
            dayLabels.push(day.toLocaleDateString());
        }

        trades.forEach(trade => {
            // @ts-ignore
            if (!brokerTradeCounts[trade.executingBroker]) {
                // @ts-ignore
                brokerTradeCounts[trade.executingBroker] = Array(30).fill(0);
            }
        });

        // Process trades
        const countsByDay = {};
        trades.forEach(trade => {
            const tradeDate = new Date(trade.tradeDate).toLocaleDateString();
            const executionTime = dayjs(trade.reportedTime).tz('UTC');
            const reportedTime = dayjs(trade.executionTime).tz('UTC');
            const diffInMinutes = Math.abs(executionTime.diff(reportedTime, 'minute'));
            const isCompliant = diffInMinutes <= 30;

            if (dayLabels.includes(tradeDate)) {
                const dayIndex = dayLabels.indexOf(tradeDate);
                // @ts-ignore
                countsByDay[tradeDate] = (countsByDay[tradeDate] || 0) + 1;
                if (isCompliant) {
                    dailyComplianceCounts[dayIndex] = (dailyComplianceCounts[dayIndex] || 0) + 1;
                }
                // @ts-ignore
                brokerTradeCounts[trade.executingBroker][dayIndex] += 1;
            }
        });

        const percentageData = dayLabels.map((date, index) => {
            // @ts-ignore
            const totalTrades = countsByDay[date] || 0;
            return totalTrades ? ((dailyComplianceCounts[index] || 0) / totalTrades) * 100 : 0;
        });

        const series = [
            {
                name: '% DMIST Compliant',
                type: 'column',
                data: percentageData
            },
            ...Object.keys(brokerTradeCounts).map(broker => ({
                name: `${broker}`,
                type: 'line',
                // @ts-ignore
                data: brokerTradeCounts[broker]
            }))
        ];

        // Chart options
        const options = {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                }
            },
            stroke: {
                width: [0, 2, 2],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            dataLabels: {
                enabled: false
            },
            labels: dayLabels,
            xaxis: {
                type: 'category',
                lines: {
                    show: false,
                },
                labels: {show: false}
            },
            yaxis: [
                {
                    title: {
                        text: '% Compliant'
                    },
                    min: 0,
                    max: 100
                },
                {
                    opposite: true,
                    title: {
                        text: 'Trades by Broker'
                    }
                }
            ],
            tooltip: {
                shared: true,
                intersect: false
            }
        };

        // @ts-ignore
        setChartData({ series, options });
    };

    useEffect(() => {
        if (trades.length > 0) {
            processTradesForChart();
        }
    }, [trades]);

    if (loading) {
        return (
            <Stack direction="column" alignItems="center">
                <MoonLoader loading={loading} size={50} />
            </Stack>
        );
    }

    return (
        <Stack direction="column">
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={350}
            />
        </Stack>
    );
}
