import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import { calculateTotalContracts } from '../utils/calculateTradeData';

// @ts-ignore
const Contracts = ({ trades }) => {
    const totalContracts = calculateTotalContracts(trades);

    return (
        <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                <Stack spacing={1}>
                    <Typography color="text.secondary" variant="overline">
                        Total Contracts
                    </Typography>
                    <Typography variant="h4">{totalContracts}</Typography>
                </Stack>
                <Avatar sx={{ background: 'blue'}}>
                    <FeedIcon fontSize="large" />
                </Avatar>
            </Stack>
        </Stack>
    );
};

export default Contracts;
