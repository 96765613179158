import {Company, Trade} from "../../../types/APITypes";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const calculateTotalTrades = (trades: Trade[]) => {
    return trades.length;
};

export const calculateTotalContracts = (trades: Trade[]) => {
    return trades.reduce((sum, trade) => sum + trade.quantity, 0);
};

export const calculateMonthlyTrades = (trades: Trade[]) => {
    const now = dayjs();
    const thirtyDaysAgo = now.subtract(30, 'day');
    return trades.filter(trade => {
        const tradeDate = dayjs(trade.tradeDate).tz('UTC');
        return tradeDate.isAfter(thirtyDaysAgo) && tradeDate.isBefore(now);
    });
};

export const calculateWeeklyTrades = (trades: Trade[]) => {
    const now = dayjs();
    const thirtyDaysAgo = now.subtract(7, 'day');
    return trades.filter(trade => {
        const tradeDate = dayjs(trade.tradeDate).tz('UTC');
        return tradeDate.isAfter(thirtyDaysAgo) && tradeDate.isBefore(now);
    });
};

export const calculateTotalBrokers = (trades: Trade[], companies: Company[]) => {
    const activeBrokers = new Set(companies.map(company => company.shortName));

    const brokerTradeCount: { [key: string]: number } = {};

    trades.forEach(trade => {
        if (activeBrokers.has(trade.executingBroker)) {
            brokerTradeCount[trade.executingBroker] = (brokerTradeCount[trade.executingBroker] || 0) + 1;
        }
    });

    return Object.keys(brokerTradeCount).length;
};

export const calculateCompliantTradePercentage = (trades: Trade[]) => {
    if (trades.length === 0) {
        return 0;
    }

    let compliantTradeCount = 0;

    trades.forEach(trade => {
        const executionTime = dayjs(trade.executionTime).tz('UTC');
        const reportedTime = dayjs(trade.reportedTime).tz('UTC');
        const diffInMinutes = Math.abs(executionTime.diff(reportedTime, 'minute'));

        if (diffInMinutes <= 30) {
            compliantTradeCount++;
        }
    });

    return (compliantTradeCount / trades.length) * 100;
};
